<template>
  <section>
    <div class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Jalousien / Innenjalousien <br />
    </div>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h1 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Germania - Innenjalousien
      </h1>
      <div class="grid grid-cols-2">
        <div class="col-span-full lg:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </div>

        <div class="col-span-full lg:col-span-1">
          <h2
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Lichtregulierung und Sichtschutz im klassischen Design
          </h2>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Die Luxaflex® Jalousie-Kollektion bietet Ihnen eine große Auswahl an
            modernen Naturtönen, eleganten Texturen und exklusiven Designs.<br /><br />
            Schaffen Sie Ihre eigene persönliche Atmo-sphäre, indem Sie den
            gewünschten Lichtein-fall mit Luxaflex® Jalousien individuell
            einstellen. So passt sich das Licht in den Räumen Ihren Wünschen an.
            <br /><br />
            Qualität und Kundenzufriedenheit stehen bei uns an erster Stelle.
            Die Lamellen unsererJalousien werden aus hochbelastbarem und
            formbeständigem Aluminium gefertigt. Wählen Sie aus fünf
            verschiedenen Lamellengrößen die passende aus. Ob 16 mm oder 70 mm –
            Luxaflex® Jalousien bieten für jede Fenster-größe eine individuelle,
            maßgefertigte Lösung.
          </p>
        </div>
      </div>
      <div class="p-4">
        <div class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</div>
        <tabs :mode="mode">
          <tab title="Powerview">
            <p class="text-lg">
              <span class="font-bold">Motorisierung</span> <br />
              Was wäre, wenn Ihr Sonnenschutz Gedanken lesen könnte? Wenn er
              wüsste, wie Ihr Tag aussehen wird, und wenn er in der Lage wäre,
              ihn noch schöner werden zu lassen ? Dafür gibt es die PowerView®
              Motorisierung – ein kabelloses Motorisierungssystem, mit dem Sie
              alle Sonnenschutz-Produkte per Smartphone, Tablet oder
              Fernsteuerung bedienen können. Die Einbindung in ein Smart
              Home-System ist kein Problem.
            </p>
          </tab>
          <tab title="Kindersicherheit">
            <div class="text-lg">
              <span class="font-bold">Entwickelt mit Blick auf Sicherheit</span>
              <br />
              Kindersicherheit hat oberste Priorität. Von schnurlosen Systemen
              bis hin zu voll motorisierten Alternativen bietet Luxaflex® viele
              innovative Bediensysteme, die auf Sicherheit, Komfort und
              Schönheit ausgelegt sind. Besuchen Sie Ihren regionalen Luxaflex®-
              Fachhändler, um sich über die bestmögliche Bedienung für Ihre
              Einbau-situation zu informieren.
            </div>
          </tab>
          <tab title="Extras">
            <p class="text-lg">
              <span class="font-bold">Extras</span> <br />
              Germania - Innenjalousien ist in verschiedenen Farben und
              Ausführungen erhältich. <br />
              Besuchen Sie unsere Ausstellung oder lassen Sie sich beraten.
            </p>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/germania/innenjalousien/jalousien-1.webp"),
          alt: "innenjalousien germania",
        },
        {
          image: require("@/assets/img/germania/innenjalousien/jalousien-2.webp"),
          alt: "gruen innenjalousien",
        },
        {
          image: require("@/assets/img/germania/innenjalousien/jalousien-3.webp"),
          alt: "innenjalousien rot",
        },
        {
          image: require("@/assets/img/germania/innenjalousien/jalousien-4.webp"),
          alt: "innenjalousien rot schlafzimmer",
        },
        {
          image: require("@/assets/img/germania/innenjalousien/jalousien-5.webp"),
          alt: "innenjalousien nahaufnahme",
        },
        {
          image: require("@/assets/img/germania/innenjalousien/jalousien-6.webp"),
          alt: "innenjalousien kueche",
        },
      ],
    };
  },
};
</script>
